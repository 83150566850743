import React from 'react'
// import Header from '../components/header/header'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import logo from '../images/logo.png'

import { LazyLoadImage } from 'react-lazy-load-image-component'
//form
// import RequestForm from '../components/forms/request-form'
// import ResourcesForm from '../components/forms/resources-form'

//swiper
// import RequestSwiper from '../components/swiper/request-swiper'

//images
import ResourceImg1 from '../images/new-homepage/about-us/resource-img1.png'
import ResourceImg2 from '../images/new-homepage/about-us/resource-img2.png'
import ResourceImg3 from '../images/new-homepage/about-us/resource-img3.png'
import ResourceImg4 from '../images/new-homepage/about-us/resource-img4.png'
import ResourceImg5 from '../images/new-homepage/about-us/resource-img5.png'
import ResourceImg6 from '../images/new-homepage/about-us/resource-img6.png'
import ResourcesPhotoBg from '../images/new-homepage/about-us/resource-photo.jpg'

const Resources = () => {
  return (
    <>
      {/* <Header /> */}
      <SEO
        title="Get our best stuff each month"
        description="Each month we send our best resources to our favorite peoples' inboxes. It's one way we're trying to
pay it forward and make a difference in the world."
      />
      <div className="rfcs-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4 col-md-10 mx-auto"
              style={{ marginTop: '7.5rem' }}
            >
              {/* <div style={{ marginTop: '10rem' }}> */}
              <Link to="/">
                <img
                  // width={scrollY > 0 ? "100%" : "100%"}
                  id="logo-size"
                  src={logo}
                  alt="Sandy Scott"
                  className="logo sepia img-fluid"
                  style={{ width: '40%' }}
                />
              </Link>
              <div
                className="mt-5"
                // style={{
                //   height: '110vh',
                //   display: 'flex',
                //   flexDirection: 'column',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   padding: '8rem 0',
                // }}
              >
                <h1 className="mb-0">Get our best stuff each month</h1>
                <h4 className="my-4">
                  Each month we send our best resources to our favorite peoples'
                  inboxes. It's one way we're trying to pay it forward and make
                  a difference in the world.
                </h4>
                <p className="mb-0">
                  You can expect a distilled set of case studies, mental models,
                  tips for meetings and anything else we think might support you
                  to lead and live with purpose.
                </p>{' '}
                <iframe
                  src="../../resources-form.html"
                  title="Resources Form"
                  width="100%"
                  height="400px"
                  border="none"
                  className="w-100"
                  style={{ border: 'none' }}
                />
                <div
                  id="resource-form-wrapper"
                  style={{
                    height: '70vh',
                    marginTop: '1rem',
                  }}
                >
                  <p className="mb-0">
                    {' '}
                    Here’s some of our popular resources from last year, which
                    we’ve since posted to LinkedIn:
                  </p>
                  <div className="container mt-4 resources-boxes">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 pl-lg-0 pl-md-0 mb-4 mb-md-0">
                        <a
                          href="https://www.linkedin.com/pulse/formula-get-zone-sandy-scott-fache-mpa/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3D"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 1"
                            className="img-fluid"
                            src={ResourceImg1}
                          />
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-lg-0 pr-md-0 mb-4 mb-md-0">
                        <a
                          href="https://www.linkedin.com/pulse/youre-physician-equation-can-turn-your-project-around-sandy/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3D"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 2"
                            className="img-fluid"
                            src={ResourceImg2}
                          />
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 pl-lg-0 pl-md-0 mt-lg-4 mt-md-4 mb-4 mb-md-0">
                        <a
                          href="https://www.linkedin.com/pulse/difference-between-consulting-coaching-mentorship-scott-fache-mpa/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3D"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 3"
                            className="img-fluid"
                            src={ResourceImg3}
                          />
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-lg-0 pr-md-0 mt-lg-4 mt-md-4 mb-4 mb-md-0">
                        <a
                          href="https://www.linkedin.com/pulse/leading-people-anxiety-sandy-scott-fache-mpa/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3D"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 4"
                            className="img-fluid"
                            src={ResourceImg4}
                          />
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 pl-lg-0 pl-md-0 mt-lg-4 mt-md-4 mb-4 mb-md-0">
                        <a
                          href="https://www.linkedin.com/pulse/hr-needs-understand-before-launching-physician-scott-fache-mpa/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3D"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 5"
                            className="img-fluid"
                            src={ResourceImg5}
                          />
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 pr-lg-0 pr-md-0 mt-lg-4 mt-md-4">
                        <a
                          href="https://www.linkedin.com/pulse/ask-question-refocus-any-meeting-sandy-scott-fache-mpa/?trackingId=8PILNVZYRDyCprnlRPTI7A%3D%3"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <LazyLoadImage
                            alt="Resource 6"
                            className="img-fluid"
                            src={ResourceImg6}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <p>
                    Each month we send our best resources to our favorite
                    peoples' inboxes. It's one way we're trying to pay it
                    forward and make a difference in the world.
                  </p>
                  <p>
                    You can expect a distilled set of case studies, mental
                    models, tips for meetings and anything else we think might
                    support you to lead and live with purpose.
                  </p>
                </div> */}
              </div>
              {/* </div> */}
            </div>
            <div className="col-lg-6 px-0 w-100">
              <div
                // className="test"
                style={{
                  height: '100vh',
                  width: '50%',
                  position: 'fixed',
                  background: `url(${ResourcesPhotoBg})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '50% 50%',
                }}
              >
                {/* <RequestSwiper /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Resources
